import React from "react"
import Frame from "../components/frame"
import style from "./style/about.module.css"
import { Link } from "gatsby"

export default () => {
    return (
        <Frame>
            <div className={`text-serif sectionbox`}>
                <h3 className={`text-sans-serif-display text-bold col-fg`}>
                    About me
                </h3>
                <p>
                    I'm interested in making things that help humans interact with machines. Whether it be a solid interface, a good website, or a useful tool, I'm always wondering how to make a better and more logical experience for a user. I'm a student at UNC Chapel Hill studying computer science and music.
                </p>
                <p>
                    I'm proficient in C, Java, Javascript, and some common JS frameworks and libraries (React, Svelte, Webpack, etc). In my spare time I enjoy tinkering with Arch Linux and working on my shell wizardry skills. I believe that some of the best ways to learn about things is to play with them, get confused with them, and fix them. I like to write about some of the things I'm doing, hence my <Link to="/blog">blog</Link>.
                </p>
                <h3 className={`text-sans-serif-display text-bold col-fg`} style={{marginTop: '15px'}}>
                    About This Site
                </h3>
                <p>This site was created from scratch with <a href="https://www.gatsbyjs.com" target="_blank">Gatsby</a>.</p>


                <h3 className={`text-sans-serif-display text-bold col-fg`} style={{marginTop: '15px'}} id="contact">Connect</h3>
                <ul>
                    <li>
                    <a href="mailto:hi@benjdod.com">Contact me</a> if you have any questions, comments, concerns, or any messages friendly or otherwise.
                    </li>
                    <li>
                    <a href="https://github.com/benjdod">GitHub</a> is where you can find my projects and snippets.
                    </li>
                </ul>
            </div>
            
        </Frame>
    )
}
